<div>
  <label *ngFor="let course of coursesKeys" class="courses-filter">
    <input type="checkbox" [(ngModel)]="courses[course]" (change)="initDisplayTimetable()">
    {{course}}
  </label>
</div>
<table *ngIf="displayTimetable">
  <thead>
    <tr>
      <td></td>
      <th *ngFor="let hour of displayTimetable.hours">
        {{hour.from | shortTime}} - {{hour.to | shortTime}}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let day of displayTimetable.days">
      <tr *ngFor="let line of day.lines; first as isFirst">
        <th *ngIf="line.index === 0" [attr.rowSpan]="day.lines.length" class="day" [class.first-row]="isFirst">{{dayNames[day.index]}}</th>
        <td *ngFor="let slot of line.slots" [attr.colSpan]="slot.span" [ngClass]="getSlotClasses(slot.lesson)" [class.first-row]="isFirst">
          <app-lesson *ngIf="slot.lesson" [lesson]="slot.lesson"></app-lesson>
          <app-lesson-edit *ngIf="slot.lesson" [lesson]="slot.lesson">
          </app-lesson-edit>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<app-comments [timetable]="timetable"></app-comments>
<div *ngIf="!displayTimetable">
  <h1>The timetable does not exist</h1>
</div>
