<button *ngIf="changed" class="save-btn" (click)="changesSave()">Save changes</button>
<button *ngIf="changed" class="discard-btn" (click)="changesDiscard()">Discard changes</button>

<nav class="navbar navbar-dark">
  <a routerLink="/">
    <h1>Timetable Planner</h1>
  </a>
  <ul>
    <a routerLink="timetable" routerLinkActive="active" class="nav-item">
      <li>Timetable</li>
    </a>
    <a routerLink="courses" routerLinkActive="active" class="nav-item">
      <li>Courses</li>
    </a>
    <a routerLink="help" routerLinkActive="active" class="nav-item">
      <li>Help</li>
    </a>
  </ul>
  <div class="spacer"></div>
  <label class="checkbox">
    Show All
    <input type="checkbox" (change)="showAllChanged($event.target.checked)" [checked]="showAll">
    <span class="checkmark">
      <i class="material-icons">check</i>
    </span>
  </label>
  <label class="file-input">
    Open timetable
    <input type="file" (change)="openTimetable($event.target.files)">
  </label>
  <button (click)="downloadTimetable()">Download timetable</button>
  <label class="file-input">
    Import IS XML
    <input type="file" (change)="importFile($event.target.files)">
  </label>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>

<footer>
  Created by <a href="https://github.com/JurajMarcin" target="_blank">Juraj Marcin</a>.
  Source Code available on <a href="https://github.com/JurajMarcin/MUNITimetablePlanner" target="_blank">GitHub</a>.
</footer>
