<h1>Help</h1>
<ul>
  <li>
    <a [routerLink]="[]" [fragment]="'wtf'">What is Timetable Planner?</a>
  </li>
  <li>
    <a [routerLink]="[]" [fragment]="'import'">How to import timetable from IS?</a>
  </li>
  <li>
    <a [routerLink]="[]" [fragment]="'usage'">How to use Timetable Planner?</a>
  </li>
</ul>

<section id="wtf">
  <h2>What is Timetable Planner?</h2>
  <p>
    This Timetable Planner is intended for students using the best<sup>1</sup> university <a href="https://is.muni.cz"
      target="_blank">Information System</a> developed by the <a href="https://fi.muni.cz" target="_blank"> Faculty of
      Informatics, Masaryk University, Brno</a>.
    They can use this planner to plan choose the best matching seminar groups before the click war begins.
  </p>
  <p>
    You can find more information in the <a [routerLink]="[]" [fragment]="'usage'">Usage section</a>.
  </p>
  <p>
    Source code of this web application can be found on <a href="https://github.com/JurajMarcin/MUNITimetablePlanner"
      target="_blank">GitHub</a>.
  </p>
</section>

<section id="import">
  <h2>How to import timetable from IS?</h2>
  <p>
    Download the timetable in XML (Programmer) format, make sure to have 'include teachers' and 'include all seminar
    groups of the courses displayed'.
  </p>
  <p>
    You can download it by right clicking the following link and selecting Save As:
    <a href="https://is.muni.cz/auth/rozvrh/zobraz/muj?format=xml&bv=&tyden=0&od=&do=&vcuc=1&seminare_vsechny=1&vybrat=1"
      target="_blank">https://is.muni.cz/auth/rozvrh/zobraz/muj?format=xml&amp;bv=&amp;tyden=0&amp;od=&amp;do=&amp;vcuc=1&amp;seminare_vsechny=1&amp;vybrat=1</a>.
    It will download the timetable for the term you have currently selected in IS. If the selected term is not
    correct,
    go to the <a href="https://is.muni.cz/auth/rozvrh/zobraz/muj" target="_blank">IS Timetables application</a> and
    change the selected term using the menu at the top.
  </p>
  <p>
    Then import the downloaded file using the 'Import IS XML' button.
  </p>
</section>

<section id="usage">
  <h2>How to use Timetable Planner?</h2>
  <p>
    You can change the priority of seminars (lower the number means higher priority). Overlaping seminars are
    ordered by priority. Lectures have the highest priority by default.
  </p>
  <img src="/assets/help/04_priority.png">
  <p>
    You can also hide lesson, you don't want to see. Hidden lessons can be displayed using show all checkbox in the
    page
    header.
  </p>
  <img src="/assets/help/05_visibility.png">
  <p>
    Colour of the lesson is determined by its priority.
  </p>
  <div class="priority-help">
    <span class="lecture">Lecture</span>
    <span class="seminar priority-1">Seminar with priority 1</span>
    <span class="seminar priority-2">Seminar with priority 2</span>
    <span class="seminar priority-3">Seminar with priority 3</span>
    <span class="seminar priority-4">Seminar with priority 4</span>
    <span class="seminar priority-5">Seminar with priority 5</span>
    <span class="seminar priority-6">Seminar with priority 6</span>
    <span class="seminar priority-7">Seminar with priority 7</span>
    <span class="seminar priority-8">Seminar with priority 8</span>
    <span class="seminar priority-9">Seminar with priority 9</span>
    <span class="seminar priority-10">Seminar with priority 10</span>
    <span class="seminar priority-11">Seminar with priority 11</span>
    <span class="seminar priority-12">Seminar with priority 12</span>
    <span class="seminar priority-13">Seminar with priority 13</span>
    <span class="seminar priority-14">Seminar with priority 14</span>
    <span class="seminar priority-15">Seminar with priority 15</span>
    <span class="seminar priority-16">Seminar with priority 16</span>
  </div>
</section>

<footer>
  <sup>1</sup>decided by myself, but I think I am not alone
</footer>
